import React, { useEffect, useState } from 'react';
import { MainContainer } from '../components/Default/MainContainer/MainContainer';
import { Link, useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import {
  CircularProgress,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import { URLS } from '../utils/urls.util';
import { useApi } from '../hooks/useApi';
import { getAllKeycloakGroups } from '../api/UserApi';
import { pagesIds } from '../utils/pages';

export const RolesPage = () => {
  const [roles, setRoles] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');
  const getAllKeycloakGroupsApi = useApi(getAllKeycloakGroups);

  useEffect(() => {
    fetchGroups();
  }, []);

  const [permission, setPermission] = useState({});

  const navigate = useNavigate();

  useEffect(() => {
    if (permission && Object.keys(permission).length && !permission.view) {
      navigate(URLS.DASHBOARD);
    }
  }, [permission]);

  const fetchGroups = async (p = page, s) => {
    if (s) {
      s = {
        search,
      };
    }
    const [result, count] = await getAllKeycloakGroupsApi.sendRequest({
      first: (p - 1) * 10,
      max: 10,
      ...s,
    });

    setRoles(result);
    setTotalCount(count);
  };
  const breadcrumbs = [
    <Link underline="hover" key="1" color="inherit" to={URLS.DASHBOARD}>
      Главная
    </Link>,
    <Typography key="2" color="text.primary">
      Роли
    </Typography>,
  ];
  return (
    <MainContainer
      title="Роли"
      breadcrumbs={breadcrumbs}
      actionButton={
        permission?.create
          ? {
              text: 'Добавить роль',
              action: () => navigate(URLS.ROLES + '/create'),
            }
          : null
      }
      pageId={pagesIds.roles}
      setPermission={setPermission}
    >
      <TextField
        fullWidth
        label="Поиск по названию"
        sx={{ mb: 4 }}
        onChange={({ target: { value } }) => {
          setSearch(value);
          getAllKeycloakGroupsApi
            .sendRequest(
              value
                ? { search: value, first: 0, max: 10 }
                : { first: 0, max: 10 },
            )
            .then(([result, count]) => {
              setPage(1);
              setRoles(result);
              setTotalCount(count);
            });
        }}
      />
      {getAllKeycloakGroupsApi.loading ? (
        <CircularProgress />
      ) : (
        <TableContainer component={Paper} sx={{ mb: 4 }}>
          <Table
            sx={{ minWidth: 650 }}
            aria-label="simple table"
            className="hover-data-table"
          >
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontWeight: 'bold' }}>Роль</TableCell>
                <TableCell sx={{ fontWeight: 'bold' }}>Дата создания</TableCell>
                <TableCell sx={{ fontWeight: 'bold' }}>
                  Дата изменения
                </TableCell>
                <TableCell sx={{ fontWeight: 'bold' }}>
                  Автор изменения
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {roles?.map((role) => (
                <TableRow
                  key={role.id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  onClick={() => navigate(URLS.ROLES + '/' + role.id)}
                >
                  <TableCell component="th" scope="row">
                    {role?.roleName}
                  </TableCell>
                  <TableCell>
                    {role?.attributes?.CreateDate
                      ? format(
                          new Date(role?.attributes?.CreateDate[0]),
                          'dd.MM.yyyy в HH:mm:ss',
                        )
                      : '-'}
                  </TableCell>
                  <TableCell>
                    {role?.attributes?.UpdateDate
                      ? role?.attributes?.IsReadOnly !== 'True'
                        ? format(
                            new Date(role?.attributes?.UpdateDate[0]),
                            'dd.MM.yyyy в HH:mm:ss',
                          )
                        : '-'
                      : role?.attributes?.updateDate
                      ? role?.attributes?.IsReadOnly !== 'True'
                        ? format(
                            new Date(role?.attributes?.updateDate[0]),
                            'dd.MM.yyyy в HH:mm:ss',
                          )
                        : '-'
                      : '-'}
                  </TableCell>
                  <TableCell>
                    {role?.attributes?.UpdatedBy &&
                    role?.attributes?.IsReadOnly !== 'True'
                      ? role?.attributes?.UpdatedBy[0]
                      : '-'}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      {!getAllKeycloakGroupsApi.loading && Math.round(totalCount / 10) > 0 && (
        <Pagination
          count={Math.ceil(totalCount / 10)}
          page={page}
          sx={{ mb: 4 }}
          onChange={(event, value) => {
            setPage(value);
            fetchGroups(value, search);
          }}
        />
      )}
    </MainContainer>
  );
};
